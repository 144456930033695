import LogoImage from '../../../assets/icons/icon.png';

const Loading = () => {
  return (
    <div className='flex h-screen items-center justify-center'>
      <div className='flex max-w-[20px] cursor-pointer items-center'>
        <img className='animate-ping' src={LogoImage} alt='Logo' />
      </div>
    </div>
  );
};

export default Loading;
