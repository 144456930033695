import { Fragment, useEffect, useState } from 'react';
import { Transition } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/20/solid';
import { CheckCircleIcon, XCircleIcon } from '@heroicons/react/24/outline';
import { INotifications } from '../../utils/context';

type Props = {
  data?: INotifications;
};

const Notification = ({ data }: Props) => {
  const [show, setShow] = useState(false);

  useEffect(() => {
    if (data?.isShow !== undefined) {
      setShow(data?.isShow);
    }
  }, [data?.isShow]);

  return (
    <div
      aria-live='assertive'
      className='z-20 pointer-events-none w-full fixed bottom-0 right-0 flex items-end px-4 py-6 sm:items-start sm:p-6'
    >
      <div className='flex w-full flex-col items-center space-y-4 sm:items-end'>
        <Transition
          show={show}
          as={Fragment}
          enter='transform ease-out duration-500 transition'
          enterFrom='translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2'
          enterTo='translate-y-0 opacity-100 sm:translate-x-0'
          leave='transition ease-in duration-700'
          leaveFrom='opacity-100'
          leaveTo='opacity-0'
        >
          <div className='pointer-events-auto w-full max-w-sm overflow-hidden rounded-lg bg-gray-800 shadow-lg ring-1 ring-black ring-opacity-5'>
            <div className='p-4'>
              <div className='flex items-start'>
                <div className='flex-shrink-0'>
                  {data?.icon ? (
                    data.icon
                  ) : data?.isError ? (
                    <div className='text-red-500'>
                      <XCircleIcon className='h-6 w-6 text-red-500' aria-hidden='true' />
                    </div>
                  ) : (
                    <div className='text-green-500'>
                      <CheckCircleIcon className='h-6 w-6 text-green-500' aria-hidden='true' />
                    </div>
                  )}
                </div>
                <div className='ml-3 w-0 flex-1 pt-0.5'>
                  <p className='text-sm font-medium text-white'>{data?.title}</p>
                  {data?.description ? <p className='mt-1 text-sm text-white'>{data.description}</p> : null}
                </div>
                <div className='ml-4 flex flex-shrink-0'>
                  <button
                    type='button'
                    className='inline-flex rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2'
                    onClick={() => {
                      setShow(false);
                    }}
                  >
                    <XMarkIcon className='h-5 w-5' aria-hidden='true' />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Transition>
      </div>
    </div>
  );
};

export default Notification;
