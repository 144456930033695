import axios from 'axios';
import { API_ENDPOINT } from '../constant/endpoint/api-endpoint';
import authHeader from './auth-header';

const API_URL = process.env.REACT_APP_BASE_API_URI;

export interface ILogin {
  email: string;
  password: string;
  remember?: boolean;
}

export interface IRegister {
  email: string;
  password: string;
  confirm_password: boolean;
}

export interface IForgotPassword {
  email: string;
}

export interface IChangePassword {
  password: string;
  confirm_password: boolean;
}

class AuthApi {
  async login(data: ILogin) {
    const response = await axios.post(
      `${API_URL}/${API_ENDPOINT.AUTH.LOGIN}`,
      { ...data },
      {
        headers: authHeader(),
      },
    );
    return response.data;
  }

  async logout() {
    localStorage.removeItem('token');
  }

  async register(data: IRegister) {
    const response = await axios.post(
      `${API_URL}/${API_ENDPOINT.USER.REGISTER}`,
      { ...data },
      {
        headers: authHeader(),
      },
    );
    return response.data;
  }

  async changePassword(data: IChangePassword, token: string) {
    const response = await axios.put(
      `${API_URL}/${API_ENDPOINT.USER.CHANGE_PASSWORD}`,
      { ...data },
      {
        headers: authHeader(token),
      },
    );
    return response.data;
  }

  async checkToken(token: string) {
    const response = await axios.get(`${API_URL}/${API_ENDPOINT.EMAIL.CHECK_TOKEN}`, {
      headers: authHeader(token),
    });
    return response.data;
  }

  async verifyEmail(token: string) {
    const response = await axios.get(`${API_URL}/${API_ENDPOINT.EMAIL.VERIFY}`, {
      headers: authHeader(token),
    });
    return response.data;
  }

  async forgotPassword(data: IForgotPassword) {
    const response = await axios.post(
      `${API_URL}/${API_ENDPOINT.USER.FORGOT_PASSWORD}`,
      { ...data },
      {
        headers: authHeader(),
      },
    );
    return response.data;
  }

  async getProfile() {
    const response = await axios.get(`${API_URL}/${API_ENDPOINT.AUTH.PROFILE}`, {
      headers: authHeader(),
    });
    return response.data;
  }
}

export default new AuthApi();
