import { FC } from 'react';
import Router from './Routes';

const App: FC = () => {
  return (
    <div className='App'>
      <Router />
    </div>
  );
};

export default App;
