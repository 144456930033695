import { Fragment, useContext } from 'react';
import { Disclosure, Menu, Transition } from '@headlessui/react';
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline';
import { cx } from '@emotion/css';
import { useNavigate, useLocation } from 'react-router-dom';
import PATH_ENDPOINT from '../../constant/endpoint/path-endpoint';
import { MENU } from '../../constant/endpoint/menu-endpoint';
import LogoImage from '../../../assets/icons/icon.png';
import UserLogo from '../../../assets/icons/user.png';
import { MainCtx } from '../../utils/context';
import { ROLE } from '../../constant/enum/role';
import AuthAPI from '../../api/auth.service';
import { useTranslation } from 'react-i18next';

const Navbar = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const { user } = useContext(MainCtx);

  const logout = () => {
    AuthAPI.logout().then(() => {
      window.location.href = `${window.location.origin}${PATH_ENDPOINT.LOGIN}`;
    });
  };

  return (
    <Disclosure as='nav' className='bg-gray-800 shadow'>
      {({ open }) => (
        <>
          <div className='mx-auto px-12'>
            <div className='flex h-16 justify-between'>
              <div className='flex'>
                <div
                  className='flex max-w-[20px] cursor-pointer items-center'
                  onClick={() => navigate(PATH_ENDPOINT.HOME, { replace: true })}
                >
                  <img src={LogoImage} alt='Logo' />
                </div>
                <div className='hidden sm:ml-6 sm:flex sm:space-x-8'>
                  {Object.keys(MENU).map((item, index) =>
                    user && user.roles?.includes(MENU[item].permission) ? (
                      <div
                        key={`${MENU[item]}-${index}`}
                        onClick={() => navigate(MENU[item].path, { replace: true })}
                        className={cx(
                          'inline-flex items-center px-1 pt-1 text-sm font-medium text-white cursor-pointer',
                          location.pathname.split('/')[1] === MENU[item].path.split('/')[1] && !user.roles?.includes(ROLE.ADMIN)
                            ? 'border-b-2 border-red-500'
                            : location.pathname === MENU[item].path
                            ? 'border-b-2 border-red-500'
                            : '',
                        )}
                      >
                        {MENU[item].name}
                      </div>
                    ) : null,
                  )}
                </div>
              </div>
              {user ? (
                <div className='hidden sm:ml-6 sm:flex sm:items-center'>
                  <Menu as='div' className='relative ml-3'>
                    <div>
                      <Menu.Button className='flex rounded-full bg-white text-sm focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2'>
                        <img
                          className='h-8 w-8 rounded-full object-cover'
                          src={user?.profile ? user?.profile.url : UserLogo}
                          alt='profile'
                        />
                      </Menu.Button>
                    </div>
                    <Transition
                      as={Fragment}
                      enter='transition ease-out duration-200'
                      enterFrom='transform opacity-0 scale-95'
                      enterTo='transform opacity-100 scale-100'
                      leave='transition ease-in duration-75'
                      leaveFrom='transform opacity-100 scale-100'
                      leaveTo='transform opacity-0 scale-95'
                    >
                      <Menu.Items className='absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none'>
                        <Menu.Item>
                          {({ active }) => (
                            <div
                              onClick={() => navigate(PATH_ENDPOINT.PROFILE, { replace: true })}
                              className={cx(active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700 cursor-pointer')}
                            >
                              {t('profile')}
                            </div>
                          )}
                        </Menu.Item>
                        {user.roles?.includes(ROLE.USER) ? (
                          <Menu.Item>
                            {({ active }) => (
                              <div
                                onClick={() => navigate(PATH_ENDPOINT.SETTING, { replace: true })}
                                className={cx(active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700 cursor-pointer')}
                              >
                                {t('setting')}
                              </div>
                            )}
                          </Menu.Item>
                        ) : null}
                        <Menu.Item>
                          {({ active }) => (
                            <div
                              onClick={() => logout()}
                              className={cx(active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700 cursor-pointer')}
                            >
                              {t('logout')}
                            </div>
                          )}
                        </Menu.Item>
                      </Menu.Items>
                    </Transition>
                  </Menu>
                </div>
              ) : null}
              <div className={cx(user ? '-mr-2 flex items-center sm:hidden' : 'hidden')}>
                <Disclosure.Button className='inline-flex items-center justify-center rounded-md p-2 text-gray-400'>
                  {open ? (
                    <XMarkIcon className='block h-6 w-6' aria-hidden='true' />
                  ) : (
                    <Bars3Icon className='block h-6 w-6' aria-hidden='true' />
                  )}
                </Disclosure.Button>
              </div>
            </div>
          </div>

          <Disclosure.Panel className='sm:hidden'>
            <div className='space-y-1 pt-2 pb-3'>
              {Object.keys(MENU).map((item, index) =>
                user && user.roles?.includes(MENU[item].permission) ? (
                  <Disclosure.Button
                    key={`${MENU[item]}-${index}`}
                    as='a'
                    href='#'
                    onClick={() => navigate(MENU[item].path, { replace: true })}
                    className={cx(
                      'block py-2 pl-3 pr-4 text-base font-medium',
                      location.pathname === MENU[item].path ? 'border-l-4 border-indigo-400 bg-gray-100' : 'text-white',
                    )}
                  >
                    {MENU[item].name}
                  </Disclosure.Button>
                ) : null,
              )}
            </div>
            {user ? (
              <div className='border-t border-gray-200 pt-4 pb-3'>
                <div className='flex items-center px-4'>
                  <div className='flex-shrink-0 rounded-full bg-white'>
                    <img className='h-10 w-10 rounded-full' src={UserLogo} alt='profile-mini' width={50} height={50} />
                  </div>
                  <div className='ml-3 text-white'>
                    <div className='text-base font-medium'>{user.name}</div>
                    <div className='text-sm font-medium'>{user.email}</div>
                  </div>
                </div>
                <div className='mt-3 space-y-1 text-white'>
                  <Disclosure.Button
                    as='a'
                    href='#'
                    onClick={() => navigate(PATH_ENDPOINT.PROFILE, { replace: true })}
                    className='block px-4 py-2 text-base font-medium hover:bg-gray-100 hover:text-gray-800'
                  >
                    {t('profile')}
                  </Disclosure.Button>
                  {user.roles?.includes(ROLE.USER) ? (
                    <Disclosure.Button
                      as='a'
                      href='#'
                      onClick={() => navigate(PATH_ENDPOINT.SETTING, { replace: true })}
                      className='block px-4 py-2 text-base font-medium hover:bg-gray-100 hover:text-gray-800'
                    >
                      {t('setting')}
                    </Disclosure.Button>
                  ) : null}
                  <Disclosure.Button
                    as='a'
                    href='#'
                    onClick={() => logout()}
                    className='block px-4 py-2 text-base font-medium hover:bg-gray-100 hover:text-gray-800'
                  >
                    {t('logout')}
                  </Disclosure.Button>
                </div>
              </div>
            ) : null}
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
};

export default Navbar;
