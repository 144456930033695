import { createContext, useEffect, useState, ReactNode } from 'react';
import AuthAPI from '../../common/api/auth.service';

type Props = {
  children: JSX.Element;
};

export interface INotifications {
  title?: string | null;
  description?: string;
  icon?: ReactNode;
  isError?: boolean;
  isShow: boolean;
}

interface MainContextInterface {
  user: any;
  auth: () => void;
  notification?: INotifications;
  setNotification: (data: INotifications) => void;
}

export const MainCtx = createContext({} as MainContextInterface);

const Context = ({ children }: Props) => {
  const [user, setUser] = useState<any>();
  const [notification, setNotification] = useState<INotifications>();

  useEffect(() => {
    if (notification) {
      setTimeout(() => setNotification({ isShow: false }), 4000);
    }
  }, [notification]);

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      auth();
    }
  }, [localStorage.getItem('token')]);

  const auth = () => {
    AuthAPI.getProfile()
      .then(res => setUser(res))
      .catch(() => AuthAPI.logout());
  };

  const MainContext: MainContextInterface = {
    user,
    auth,
    notification,
    setNotification,
  };

  return <MainCtx.Provider value={MainContext}>{children}</MainCtx.Provider>;
};

export default Context;
