import { cx } from '@emotion/css';
import { ReactNode } from 'react';
import Footer from './footer.component';
import Navbar from './navbar.component';

type Props = {
  children?: ReactNode;
};

const Layout = ({ children }: Props) => {
  return (
    <main className='app'>
      <Navbar />
      <div className={cx('min-h-screen')}>{children}</div>
      <Footer />
    </main>
  );
};

export default Layout;
