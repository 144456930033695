const PATH_ENDPOINT = {
  HOME: '/',
  REGISTER: '/register',
  LOGIN: '/login',
  PROFILE: '/profile',
  SETTING: '/setting',
  USER_MANAGEMENT: '/user/management',
  CREATE_NOTE: '/record/create',
  NOTE: '/record',
  DETAIL_NOTE: '/record/:id',
  EDIT_NOTE: '/record/edit/:id',
  BOARD: '/board',
  EDIT_PROJECT: '/board/project/:id',
  CREATE_PROJECT: '/board/project/create',
  TODO: '/board/project/:projectId/todo',
  CREATE_TODO: '/board/project/:projectId/todo/create',
  EDIT_TODO: '/board/project/:projectId/todo/:id/edit',
  VIEW_TODO: '/board/project/:projectId/todo/:id',
  CALENDAR: '/calendar',
  TAGS: '/tags',
  CREATE_TAGS: '/tags/create',
  EDIT_TAGS: '/tags/:id/edit',
  FORGOT_PASSWORD: '/forgot-password',
  RESET_PASSWORD: '/reset-password',
  VERIFY_EMAIL: '/verify',
};

export default PATH_ENDPOINT;
