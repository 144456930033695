import { FC, Suspense, lazy, useContext } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Layout from './common/base-ui/layout/layout.component';
import Notification from './common/base-ui/notifications/notification.component';
import PATH_ENDPOINT from './common/constant/endpoint/path-endpoint';
import { ROLE } from './common/constant/enum/role';
import { MainCtx } from './common/utils/context';
import Loading from './common/base-ui/loading/loading.component';
import { PERMISSION } from './common/constant/enum/permission';

const Login = lazy(() => import('./page/auth/login'));
const Register = lazy(() => import('./page/auth/register'));
const NotFound = lazy(() => import('./page/error/404'));
const Home = lazy(() => import('./page/main'));
const Dashboard = lazy(() => import('./page/main/admin/dashboard'));
const Setting = lazy(() => import('./page/setting'));
const UserManagement = lazy(() => import('./page/user/admin/user'));
const Profile = lazy(() => import('./page/user'));
const Note = lazy(() => import('./page/note'));
const CreateNote = lazy(() => import('./page/note/create'));
const ViewNote = lazy(() => import('./page/note/view'));
const EditNote = lazy(() => import('./page/note/edit'));
const Project = lazy(() => import('./page/todo/project'));
const CreateProject = lazy(() => import('./page/todo/project/create'));
const EditProject = lazy(() => import('./page/todo/project/edit'));
const TaskDetail = lazy(() => import('./page/todo/task'));
const CreateTask = lazy(() => import('./page/todo/task/create'));
const EditTask = lazy(() => import('./page/todo/task/edit'));
const ViewTask = lazy(() => import('./page/todo/task/info'));
const Calendar = lazy(() => import('./page/calendar'));
const Tags = lazy(() => import('./page/tags'));
const CreateTags = lazy(() => import('./page/tags/create'));
const EditTags = lazy(() => import('./page/tags/edit'));
const ForgotPassword = lazy(() => import('./page/forgot-password'));
const ChangePassword = lazy(() => import('./page/forgot-password/change-password'));
const VerifyEmail = lazy(() => import('./page/auth/verify'));

const Router: FC = () => {
  const { user, notification } = useContext(MainCtx);

  return (
    <div className='App'>
      <BrowserRouter>
        <Layout>
          <Suspense fallback={<Loading />}>
            <Routes>
              {user ? (
                <Route path='/'>
                  {user.roles?.includes(ROLE.USER) ? (
                    <>
                      <Route index element={<Home />}></Route>
                      <Route path={PATH_ENDPOINT.SETTING} element={<Setting />}></Route>
                      <Route path={PATH_ENDPOINT.NOTE} element={<Note />}></Route>
                      <Route path={PATH_ENDPOINT.CREATE_NOTE} element={<CreateNote />}></Route>
                      <Route path={PATH_ENDPOINT.DETAIL_NOTE} element={<ViewNote />}></Route>
                      <Route path={PATH_ENDPOINT.EDIT_NOTE} element={<EditNote />}></Route>
                      <Route path={PATH_ENDPOINT.BOARD} element={<Project />}></Route>
                      <Route path={PATH_ENDPOINT.CREATE_PROJECT} element={<CreateProject />}></Route>
                      <Route path={PATH_ENDPOINT.EDIT_PROJECT} element={<EditProject />}></Route>
                      <Route path={PATH_ENDPOINT.TODO} element={<TaskDetail />}></Route>
                      <Route path={PATH_ENDPOINT.CREATE_TODO} element={<CreateTask />}></Route>
                      <Route path={PATH_ENDPOINT.EDIT_TODO} element={<EditTask />}></Route>
                      <Route path={PATH_ENDPOINT.VIEW_TODO} element={<ViewTask />}></Route>
                      <Route path={PATH_ENDPOINT.CALENDAR} element={<Calendar />}></Route>
                      <Route path={PATH_ENDPOINT.TAGS} element={<Tags />}></Route>
                      {user?.userMember?.permission === PERMISSION.MEMBER && (
                        <>
                          <Route path={PATH_ENDPOINT.CREATE_TAGS} element={<CreateTags />}></Route>
                          <Route path={PATH_ENDPOINT.EDIT_TAGS} element={<EditTags />}></Route>
                        </>
                      )}
                    </>
                  ) : (
                    <>
                      <Route index element={<Dashboard />}></Route>
                      <Route path={PATH_ENDPOINT.USER_MANAGEMENT} element={<UserManagement />}></Route>
                    </>
                  )}
                  <Route path={PATH_ENDPOINT.PROFILE} element={<Profile />}></Route>
                </Route>
              ) : (
                <Route path='/'>
                  <Route index element={<Login />} />
                  <Route path={PATH_ENDPOINT.LOGIN} element={<Login />} />
                  <Route path={PATH_ENDPOINT.REGISTER} element={<Register />} />
                  <Route path={PATH_ENDPOINT.FORGOT_PASSWORD} element={<ForgotPassword />} />
                  <Route path={PATH_ENDPOINT.RESET_PASSWORD} element={<ChangePassword />} />
                  <Route path={PATH_ENDPOINT.VERIFY_EMAIL} element={<VerifyEmail />} />
                </Route>
              )}

              <Route path='*' element={<NotFound />} />
            </Routes>
          </Suspense>
        </Layout>
      </BrowserRouter>
      <Notification data={notification} />
    </div>
  );
};

export default Router;
