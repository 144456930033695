import { ROLE } from '../enum/role';
import PATH_ENDPOINT from './path-endpoint';

const PRIVATE_MENU = {
  DASHBOARD: {
    name: 'Dashboard',
    path: PATH_ENDPOINT.HOME,
    permission: ROLE.ADMIN,
  },
  MENAGE_USER: {
    name: 'Menage User',
    path: PATH_ENDPOINT.USER_MANAGEMENT,
    permission: ROLE.ADMIN,
  },
};

const PUBLIC_MENU = {
  HOME: {
    name: 'Home',
    path: PATH_ENDPOINT.HOME,
    permission: ROLE.USER,
  },
  NOTE: {
    name: 'Note',
    path: PATH_ENDPOINT.NOTE,
    permission: ROLE.USER,
  },
  TODO: {
    name: 'Todo',
    path: PATH_ENDPOINT.BOARD,
    permission: ROLE.USER,
  },
  CALENDAR: {
    name: 'Calendar',
    path: PATH_ENDPOINT.CALENDAR,
    permission: ROLE.USER,
  },
  TAGS: {
    name: 'Tags',
    path: PATH_ENDPOINT.TAGS,
    permission: ROLE.USER,
  },
};

type TypeOptions = {
  [key: string]: any;
};

export const MENU: TypeOptions = {
  ...PRIVATE_MENU,
  ...PUBLIC_MENU,
};
