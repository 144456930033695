import axios from 'axios';

const API_KEY = process.env.REACT_APP_BASE_API_KEY;

export default function authHeader(token?: string) {
  const currentToken = token || localStorage.getItem('token');
  if (API_KEY) {
    axios.defaults.headers['x-api-key'] = API_KEY;
  }
  if (currentToken) {
    return { 'Content-Type': 'application/json', Authorization: 'Bearer ' + currentToken };
  } else {
    return { 'Content-Type': 'application/json' };
  }
}
